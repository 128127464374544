import { objectHasKey } from '@helpers/functions'

const SystemProfilePage = () => import('@views/system/ProfilePage')

/* eslint-disable no-unused-vars */
export default {
  path: 'profile/:userId?',
  name: 'SystemProfilePage',
  component: SystemProfilePage,
  beforeEnter (to, from, next) {
    const userParam = to.params

    if (objectHasKey(userParam, 'userId') && userParam.userId) {
      next()
    } else {
      next({ name: 'SystemDashboardPage' })
    }
  },
}
