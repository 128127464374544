export default {
  label: 'A senha precisa conter:',
  rules: {
    'minUppercase': 'Min 1 Maiuscula',
    'minLowercase': 'Min 1 Minuscula',
    'minNumber':'Min 1 Numero',
    'minCharacters': 'Min 8 Caracteres',
    'specialCharacter': 'Caracter Especial'
  }
}
