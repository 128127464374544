const SystemRecordsNewsPage = () => import('@views/system/records/news/RecordsNewsPage')
const SystemRecordsNewsListPage = () => import('@views/system/records/news/RecordsNewsListPage')
const SystemRecordsNewsCreatePage = () => import('@views/system/records/news/RecordsNewsCreatePage')
const SystemRecordsNewsEditPage = () => import('@views/system/records/news/RecordsNewsEditPage')

/* eslint-disable no-unused-vars */
export default {
  path: 'news',
  name: 'SystemRecordsNewsPage',
  component: SystemRecordsNewsPage,
  children: [
    {
      path: '',
      name: 'SystemRecordsNewsListPage',
      component: SystemRecordsNewsListPage,
    },
    {
      path: 'create/:reference?',
      name: 'SystemRecordsNewsCreatePage',
      component: SystemRecordsNewsCreatePage,
    },
    {
      path: 'edit/:reference?',
      name: 'SystemRecordsNewsEditPage',
      component: SystemRecordsNewsEditPage,
      beforeEnter (to, from, next) {
        const { reference } = to.params

        if (!reference) {
          next({ name: 'SystemRecordsNewsListPage' })
        } else {
          next()
        }
      },
    },
  ],
}
