const SystemRecordsMeasuresSizePage = () => import('@views/system/records/measuresSize/RecordsMeasuresSizePage')
const SystemRecordsMeasuresSizeListPage = () => import('@views/system/records/measuresSize/RecordsMeasuresSizeListPage')
const SystemRecordsMeasuresSizeCreatePage = () => import('@views/system/records/measuresSize/RecordsMeasuresSizeCreatePage')
const SystemRecordsMeasuresSizeEditPage = () => import('@views/system/records/measuresSize/RecordsMeasuresSizeEditPage')

/* eslint-disable no-unused-vars */
export default {
  path: 'measures-size',
  name: 'SystemRecordsMeasuresSizePage',
  component: SystemRecordsMeasuresSizePage,
  children: [
    {
      path: '',
      name: 'SystemRecordsMeasuresSizeListPage',
      component: SystemRecordsMeasuresSizeListPage,
    },
    {
      path: 'create/:reference?',
      name: 'SystemRecordsMeasuresSizeCreatePage',
      component: SystemRecordsMeasuresSizeCreatePage,
    },
    {
      path: 'edit/:reference?',
      name: 'SystemRecordsMeasuresSizeEditPage',
      component: SystemRecordsMeasuresSizeEditPage,
      beforeEnter (to, from, next) {
        const { reference } = to.params

        if (!reference) {
          next({ name: 'SystemRecordsMeasuresSizeListPage' })
        } else {
          next()
        }
      },
    },
  ],
}
