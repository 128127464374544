const SystemRecordsTermsPage = () => import('@views/system/records/terms/RecordsTermsPage')
const SystemRecordsTermsListPage = () => import('@views/system/records/terms/RecordsTermsListPage')
const SystemRecordsTermsCreatePage = () => import('@views/system/records/terms/RecordsTermsCreatePage')
const SystemRecordsTermsEditPage = () => import('@views/system/records/terms/RecordsTermsEditPage')

/* eslint-disable no-unused-vars */
export default {
  path: 'terms',
  name: 'SystemRecordsTermsPage',
  component: SystemRecordsTermsPage,
  children: [
    {
      path: '',
      name: 'SystemRecordsTermsListPage',
      component: SystemRecordsTermsListPage,
    },
    {
      path: 'create/:reference?',
      name: 'SystemRecordsTermsCreatePage',
      component: SystemRecordsTermsCreatePage,
    },
    {
      path: 'edit/:reference?',
      name: 'SystemRecordsTermsEditPage',
      component: SystemRecordsTermsEditPage,
      beforeEnter (to, from, next) {
        const { reference } = to.params

        if (!reference) {
          next({ name: 'SystemRecordsTermsListPage' })
        } else {
          next()
        }
      },
    },
  ],
}
