export default {
  html: {
    title: 'Página nâo encontrada'
  },
  title: '404',
  phraseOne: 'Ah não!!',
  phraseTwo: 'Nos desculpe, mas não encontramos nenhuma louça suja!',
  phraseThree: 'Volte outra hora para nos ajudar a lavar.',
  phraseFour: 'Você está digitando uma URL incorretamente ou solicitando uma página que não existe mais.'
}
