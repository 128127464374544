const SystemRecordsStatusPage = () => import('@views/system/records/status/RecordsStatusPage')
const SystemRecordsStatusListPage = () => import('@views/system/records/status/RecordsStatusListPage')
const SystemRecordsStatusCreatePage = () => import('@views/system/records/status/RecordsStatusCreatePage')
const SystemRecordsStatusEditPage = () => import('@views/system/records/status/RecordsStatusEditPage')

/* eslint-disable no-unused-vars */
export default {
  path: 'status',
  name: 'SystemRecordsStatusPage',
  component: SystemRecordsStatusPage,
  children: [
    {
      path: '',
      name: 'SystemRecordsStatusListPage',
      component: SystemRecordsStatusListPage,
    },
    {
      path: 'create/:reference?',
      name: 'SystemRecordsStatusCreatePage',
      component: SystemRecordsStatusCreatePage,
    },
    {
      path: 'edit/:reference?',
      name: 'SystemRecordsStatusEditPage',
      component: SystemRecordsStatusEditPage,
      beforeEnter (to, from, next) {
        const { reference } = to.params

        if (!reference) {
          next({ name: 'SystemRecordsStatusListPage' })
        } else {
          next()
        }
      },
    },
  ],
}
