import { objectHasKey, isEmptyObject, isEmpty } from '@helpers/functions'

import helpersQueries from '@helpersQueries'
const { getVideoByUrlName, getRecipeByUrlName } = helpersQueries

import newsletter from './newsletter'
import pageNotFound from '../error/pageNotFound'

const StartPage = () => import('@views/website/StartPage')
const HomePage = () => import('@views/website/HomePage')
const AboutPage = () => import('@views/website/AboutPage')
const VideosPage = () => import('@views/website/VideosPage')
const VideoPage = () => import('@views/website/VideoPage')
const RecipesPage = () => import('@views/website/RecipesPage')
const RecipePage = () => import('@views/website/RecipePage')
const SearchPage = () => import('@views/website/SearchPage')

/* eslint-disable no-unused-vars */
export default {
  path: '/',
  component: StartPage,
  props: false,
  meta: {
    requiresAuth: false,
  },
  children: [
    {
      path: '/',
      name: 'HomePage',
      component: HomePage,
    },
    {
      path: '/about',
      name: 'AboutPage',
      component: AboutPage,
    },
    {
      path: '/videos',
      name: 'VideosPage',
      component: VideosPage,
    },
    {
      path: '/video/:name',
      name: 'VideoPage',
      component: VideoPage,
      async beforeEnter (to, from, next) {
        try {
          const { name: routeName } = to.params
          const validRoute = !isEmpty(to.params.name) && !isEmptyObject(to.params) && objectHasKey(to.params, 'name')

          if (validRoute) {
            const result = await getVideoByUrlName(routeName, 'id')
            const resultStatus = result.status

            if (resultStatus !== 200) next({ name: 'Error404Page' })

            next()
          } else {
            next({ name: 'Error404Page' })
          }
        } catch (e) {
          next({ name: 'Error404Page' })
        }
      },
    },
    {
      path: '/recipes',
      name: 'RecipesPage',
      component: RecipesPage,
    },
    {
      path: '/recipe/:name',
      name: 'RecipePage',
      component: RecipePage,
      async beforeEnter (to, from, next) {
        try {
          const { name: routeName } = to.params
          const validRoute = !isEmpty(to.params.name) && !isEmptyObject(to.params) && objectHasKey(to.params, 'name')

          if (validRoute) {
            const result = await getRecipeByUrlName(routeName, 'id')
            const resultStatus = result.status

            if (resultStatus !== 200) next({ name: 'Error404Page' })

            next()
          } else {
            next({ name: 'Error404Page' })
          }
        } catch (e) {
          next({ name: 'Error404Page' })
        }
      },
    },
    {
      path: '/search',
      name: 'SearchPage',
      component: SearchPage,
    },
    ...newsletter,
    pageNotFound
  ]
}
