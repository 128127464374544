import website from './website'
import system from './system'
import error from './error'

export default {
  pages: {
    website,
    system,
    error
  }
}
