import loginPage from './loginPage'
import signUpPage from './signUpPage'
import resetPasswordPage from './resetPasswordPage'
import newsletterUnsubscribePage from './newsletterUnsubscribePage'
import newsletterPage from './newsletterPage'
import homePage from './homePage'
import forgotPasswordPage from './forgotPasswordPage'
import activePage from './activePage'
import aboutPage from './aboutPage'
import videosPage from './videosPage'
import recipesPage from './recipesPage'
import searchPage from './searchPage'

export default {
  loginPage,
  signUpPage,
  resetPasswordPage,
  newsletterUnsubscribePage,
  newsletterPage,
  homePage,
  forgotPasswordPage,
  activePage,
  aboutPage,
  videosPage,
  recipesPage,
  searchPage
}
