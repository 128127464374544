const SystemRecordsSecurityPoliciesPage = () => import('@views/system/records/securityPolicies/RecordsSecurityPoliciesPage')
const SystemRecordsSecurityPoliciesListPage = () => import('@views/system/records/securityPolicies/RecordsSecurityPoliciesListPage')
const SystemRecordsSecurityPoliciesCreatePage = () => import('@views/system/records/securityPolicies/RecordsSecurityPoliciesCreatePage')
const SystemRecordsSecurityPoliciesEditPage = () => import('@views/system/records/securityPolicies/RecordsSecurityPoliciesEditPage')

/* eslint-disable no-unused-vars */
export default {
  path: 'security-policies',
  name: 'SystemRecordsSecurityPoliciesPage',
  component: SystemRecordsSecurityPoliciesPage,
  children: [
    {
      path: '',
      name: 'SystemRecordsSecurityPoliciesListPage',
      component: SystemRecordsSecurityPoliciesListPage,
    },
    {
      path: 'create/:reference?',
      name: 'SystemRecordsSecurityPoliciesCreatePage',
      component: SystemRecordsSecurityPoliciesCreatePage,
    },
    {
      path: 'edit/:reference?',
      name: 'SystemRecordsSecurityPoliciesEditPage',
      component: SystemRecordsSecurityPoliciesEditPage,
      beforeEnter (to, from, next) {
        const { reference } = to.params

        if (!reference) {
          next({ name: 'SystemRecordsSecurityPoliciesListPage' })
        } else {
          next()
        }
      },
    },
  ],
}
