const SystemRecordsFaqPage = () => import('@views/system/records/faq/RecordsFaqPage')
const SystemRecordsFaqListPage = () => import('@views/system/records/faq/RecordsFaqListPage')
const SystemRecordsFaqCreatePage = () => import('@views/system/records/faq/RecordsFaqCreatePage')
const SystemRecordsFaqEditPage = () => import('@views/system/records/faq/RecordsFaqEditPage')

/* eslint-disable no-unused-vars */
export default {
  path: 'faqs',
  name: 'SystemRecordsFaqPage',
  component: SystemRecordsFaqPage,
  children: [
    {
      path: '',
      name: 'SystemRecordsFaqListPage',
      component: SystemRecordsFaqListPage,
    },
    {
      path: 'create/:reference?',
      name: 'SystemRecordsFaqCreatePage',
      component: SystemRecordsFaqCreatePage,
    },
    {
      path: 'edit/:reference?',
      name: 'SystemRecordsFaqEditPage',
      component: SystemRecordsFaqEditPage,
      beforeEnter (to, from, next) {
        const { reference } = to.params

        if (!reference) {
          next({ name: 'SystemRecordsFaqListPage' })
        } else {
          next()
        }
      },
    },
  ],
}
