export default {
  api: {
    '01000400': 'Alguma coisa fornecida não conseguiu ser processado, verifique as informações fornecidas.',
    '01000401': 'Usuário ou Senha inválidos',
    '01004012': 'Token não fornecido',
    '01004013': 'Token inválido',
    '01004014': 'Formulário adulterado',
    '01000403': 'Acesso proibido',
    '01004041': 'Não encontrado',
    '01004042': '{resource} não encontrado (alguma informação passada esta inválida)',
    '01000415': 'Tipo de mídia não suportado (O Media Type fornecido não é suportado. O suportado é application/json)',
    '01000422': 'Erro de validação',
    '01004222': 'Usuário não ativado',
    '01004223': 'Usuário ainda não foi ativado',
    '01004224': 'Token não esta mais válido',
    '01004225': 'Este email já foi validado',
    '01004226': 'O usuário não solicitou redefinição de senha',
    '01004227': 'Senha inválida',
    '01004228': 'Erro no upload da imagem',
    '01004229': 'Este usuário ainda não foi activada',
    '01000500': 'Falha no contato com a api'
  }
}
