export default {
  html: {
    title: 'Registos',
    cardOptions: {
      videos: 'Vídeos',
      images: 'Imagens',
      themes: 'Temas',
      status: 'Status',
      faq: 'FAQ',
      terms: 'Termos',
      tags: 'Tags',
      newsletter: 'Newsletter',
      securityPolicies: 'Políticas de Segurança',
      privacyPolicies: 'Políticas de Privacidade',
      news: 'Noticias',
      mediaFan: 'Media Fan',
      recipe: 'Receitas',
      user: 'Usuários',
      userPermission: 'Permissões de Usuário',
      userType: 'Tipos de Usuário',
      measuresSize: 'Medidas - Tamanho',
      measuresUnit: 'Medidas - Unidade'
    }
  }
}
