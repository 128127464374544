export default {
  userId (state) {
    return state.id
  },
  userProfile (state) {
    return state.profile
  },
  userRole (state) {
    return state.role
  },
}
