const SystemRecordsUserPage = () => import('@views/system/records/user/RecordsUserPage')
const SystemRecordsUserListPage = () => import('@views/system/records/user/RecordsUserListPage')
const SystemRecordsUserCreatePage = () => import('@views/system/records/user/RecordsUserCreatePage')
const SystemRecordsUserEditPage = () => import('@views/system/records/user/RecordsUserEditPage')

/* eslint-disable no-unused-vars */
export default {
  path: 'users',
  name: 'SystemRecordsUserPage',
  component: SystemRecordsUserPage,
  children: [
    {
      path: '',
      name: 'SystemRecordsUserListPage',
      component: SystemRecordsUserListPage,
    },
    {
      path: 'create/:reference?',
      name: 'SystemRecordsUserCreatePage',
      component: SystemRecordsUserCreatePage,
    },
    {
      path: 'edit/:reference?',
      name: 'SystemRecordsUserEditPage',
      component: SystemRecordsUserEditPage,
      beforeEnter (to, from, next) {
        const { reference } = to.params

        if (!reference) {
          next({ name: 'SystemRecordsUserListPage' })
        } else {
          next()
        }
      },
    },
  ],
}
