import accordion from './accordion'
import badge from './badge'
import blockquote from './blockquote'
import brawlCard from './brawlCard'
import breadcrumb from './breadcrumb'
import button from './button'
import checkbox from './checkbox'
import table from './table'
import horizontalScrollingCards from './horizontalScrollingCards'

const DocsBodyPage = () => import('@views/docs/DocsBodyPage')

export default {
  path: '/docs',
  component: DocsBodyPage,
  props: false,
  meta: {
    requiresAuth: true,
  },
  children: [
    accordion,
    badge,
    blockquote,
    brawlCard,
    breadcrumb,
    button,
    checkbox,
    table,
    horizontalScrollingCards,
  ],
}
