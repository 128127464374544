import components from '@locales/pt-BR/modules/components'
import enums from '@locales/pt-BR/modules/enums'
import misc from '@locales/pt-BR/modules/misc'
import pages from '@locales/pt-BR/modules/pages'

export default {
  ...components,
  ...enums,
  ...misc,
  ...pages
}
