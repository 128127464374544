import buttons from './buttons'
import inputs from './inputs'
import errors from './errors'
import messages from './messages'
import themes from './themes'

export default {
  buttons,
  inputs,
  errors,
  messages,
  themes
}
