import users from './users.js'
import videos from './videos.js'
import recipes from './recipes.js'
import images from './images.js'
import newsletter from './newsletter.js'

export default {
  ...users,
  ...videos,
  ...recipes,
  ...images,
  ...newsletter,
}
