export default {
  system: {
  },
  website: {
    desk: {
      home: 'Home',
      about: 'Sobre',
      videos: 'Vídeos',
      recipes: 'Receitas',
      search: 'Pesquisa',
      login: 'Login',
      profile: 'Perfil'
    }
  }
}
