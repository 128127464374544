const SystemRecordsNewsletterPage = () => import('@views/system/records/newsletter/RecordsNewsletterPage')
const SystemRecordsNewsletterListPage = () => import('@views/system/records/newsletter/RecordsNewsletterListPage')
const SystemRecordsNewsletterCreatePage = () => import('@views/system/records/newsletter/RecordsNewsletterCreatePage')
const SystemRecordsNewsletterEditPage = () => import('@views/system/records/newsletter/RecordsNewsletterEditPage')

/* eslint-disable no-unused-vars */
export default {
  path: 'newsletters',
  name: 'SystemRecordsNewsletterPage',
  component: SystemRecordsNewsletterPage,
  children: [
    {
      path: '',
      name: 'SystemRecordsNewsletterListPage',
      component: SystemRecordsNewsletterListPage,
    },
    {
      path: 'create/:reference?',
      name: 'SystemRecordsNewsletterCreatePage',
      component: SystemRecordsNewsletterCreatePage,
    },
    {
      path: 'edit/:reference?',
      name: 'SystemRecordsNewsletterEditPage',
      component: SystemRecordsNewsletterEditPage,
      beforeEnter (to, from, next) {
        const { reference } = to.params

        if (!reference) {
          next({ name: 'SystemRecordsNewsletterListPage' })
        } else {
          next()
        }
      },
    },
  ],
}
