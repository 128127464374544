const ResetPasswordPage = () => import('@views/website/ResetPasswordPage')

/* eslint-disable no-unused-vars */
export default {
  path: '/reset-password',
  name: 'ResetPasswordPage',
  component: ResetPasswordPage,
  async beforeEnter (to, from, next) {
    try {
      const { token, email } = to.query

      if (token && email) {
        next()
      } else {
        next({ name: 'HomePage' })
      }
    } catch (e) {
      next({ name: 'HomePage' })
    }
  },
}
