import { computed, reactive, ref } from 'vue'
import store from '@store'
import router from '@router'
import { removeLocalToken, removeLocalRole } from '@helpers/functions'
import {
  formatDatetime,
  datetimeAddOrSubtract,
  datetimeToday,
  datetimeTomorrow,
  datetimeStartEnd,
} from '@helpers/datetime'

export default function useMixins () {
  /* VUEX GETTERS */
  const blockUi = computed(() => store.getters.blockUi)
  const theme = computed(() => store.getters.theme)
  const loginAttempts = computed(() => store.getters.loginAttempts)
  const newsletterAttempts = computed(() => store.getters.newsletterAttempts)
  const userRole = computed(() => store.getters['ModuleUser/userRole'])
  const userProfile = computed(() => store.getters['ModuleUser/userProfile'])

  /* VUEX ACTIONS */
  const changeBlockUi = value => store.dispatch('changeBlockUi', value)
  const changeTheme = value => store.dispatch('changeTheme', value)
  const clearStore = () => store.dispatch('clearStore')
  const clearErrorsStore = value => store.dispatch('ModuleErrors/clearErrorsStore', value)
  const changeLogged = value => store.dispatch('ModuleLogin/changeLogged', value)
  const clearLoginStore = () => store.dispatch('ModuleLogin/clearLoginStore')
  const changeUserRole = value => store.dispatch('ModuleUser/changeUserRole', value)
  const clearUserStore = () => store.dispatch('ModuleUser/clearUserStore')

  /* DATA */
  const localEnvs = reactive({
    nodeEnv: process.env.NODE_ENV,
    applicationEnv: process.env.APPLICATION_ENV,
    appName: process.env.VUE_APP_APP_NAME,
    baseUrl: process.env.VUE_APP_BASE,
    i18nLocale: process.env.VUE_APP_I18N_LOCALE,
    i18nFallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
    enableComments: process.env.VUE_APP_ENABLE_COMMENTS,
    tokenName: process.env.VUE_APP_TOKEN_NAME,
    restApi: {
      v1: {
        base: process.env.VUE_APP_REST_API_V1,
        status: process.env.restApiVUE_APP_REST_API_V1_STATUS,
        newsletter: process.env.VUE_APP_REST_API_V1_NEWSLETTER,
      },
    },
  })
  const startedSystemDate = ref('2016-01-01')

  /* COMPUTEDS */
  const currentTheme = computed(() => {
    return theme.value
  })
  const userImage = computed(() => {
    return !userProfile.value ? '' : userProfile.value.image.url
  })

  const hiddenLogin = computed(() => {
    return loginAttempts.value >= 4
  })

  const hiddenNewsletter = computed(() => {
    return newsletterAttempts.value >= 4
  })

  /* METHODS */
  const userHasRole = (validRoles = []) => {
    const currentRole = userRole.value

    return !!validRoles.includes(currentRole)
  }

  const preventRouterLink = (route, type = 'to') => {
    const newType = type === 'to' ? 'name' : 'fullPath'
    const currentRoute = router.currentRoute.value[newType]
    const newRoute = route[newType]

    if (!blockUi.value && newRoute !== currentRoute) router.push(route)
  }

  const changeCurrentTheme = theme => {
    if (theme.value === theme) return

    changeTheme(theme)
  }

  const setBlockUi = value => {
    if (value) {
      changeBlockUi(value)
    } else {
      const timer = 200

      setTimeout(() => {
        changeBlockUi(value)
      }, timer)
    }
  }

  // Funcao que direciona o usuario apos login de sucesso para a pagina que ele estava querendo ir ou a de dashboard caso nao estava querendo id para nenhum lugar antes de fazer o login
  const hasGoBackAfterLogin = async () => {
    const hasGoBackAfterLogin = localStorage.getItem('goBackAfterLogin')

    if (hasGoBackAfterLogin) {
      const goBackAfterLogin = JSON.parse(hasGoBackAfterLogin)

      localStorage.removeItem('goBackAfterLogin')

      router.push(goBackAfterLogin)
    } else {
      router.push({ name: 'SystemDashboardPage' })
    }
  }

  const logoutEvent = () => {
    // Remove o token local
    removeLocalToken()

    // Remove a role local
    removeLocalRole()

    // Limpa store
    clearStore()
    clearUserStore()
    clearLoginStore()
    clearErrorsStore()

    router.push({ name: 'LoginPage' })
  }

  const getCurrentRoute = () => {
    return router.currentRoute.value
  }

  const createMenuOptions = options => {
    const enabledOptions = []

    options.forEach(item => {
      if (item.show === 'all' || userHasRole(['admin', 'master', item.show])) enabledOptions.push(item)
    })

    return enabledOptions
  }

  const textUpperCase = string => {
    return string.toUpperCase()
  }

  const textCapitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  const getDate = async (datetime = '', type = 'today', datetimeStart = '', datetimeEnd = '') => {
    let currentDate = datetimeToday()

    if (type === 'yesterday') currentDate = datetimeAddOrSubtract('', '', 'subtract')
    if (type === 'tomorrow') currentDate = datetimeTomorrow()
    if (type === 'other') currentDate = formatDatetime(datetime)
    if (type === 'startEnd') {
      const start = datetimeStartEnd(datetimeStart)
      const end = datetimeStartEnd(datetimeEnd)

      currentDate.time = {
        startHour: start.startHour,
        endHour: end.endHour,
      }
    }

    return currentDate
  }

  const startEndDateCount = async (datetime = '', type = 'today', datetimeStart = '', datetimeEnd = '') => {
    const dates = await getDate(datetime, type, datetimeStart, datetimeEnd)

    return {
      created_at: {
        gte: dates.time.startHour,
        lte: dates.time.endHour,
      },
    }
  }

  return {
    blockUi,
    theme,
    userRole,
    userProfile,
    changeBlockUi,
    changeTheme,
    clearStore,
    clearErrorsStore,
    changeLogged,
    clearLoginStore,
    changeUserRole,
    clearUserStore,
    localEnvs,
    startedSystemDate,
    currentTheme,
    userImage,
    hiddenLogin,
    hiddenNewsletter,
    preventRouterLink,
    changeCurrentTheme,
    setBlockUi,
    hasGoBackAfterLogin,
    logoutEvent,
    getCurrentRoute,
    createMenuOptions,
    textUpperCase,
    textCapitalizeFirstLetter,
    getDate,
    startEndDateCount,
    userHasRole,
  }
}
