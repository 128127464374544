export default {
  html: {
    title: 'Vídeos'
  },
  title: 'Vídeos',
  description: 'Algum dos vídeos que gravamos',
  emptyList: {
    phraseOne: 'Ah, infelizmente não temos vídeos no momento!',
    phraseTwo: 'Será um prazer rever você em breve, até mais.'
  },
  buttons: {
    getMoreItems: 'Mais Vídeos'
  }
}
