<template>
  <div id="app_scope">
    <VlalgNewContentServiceWorker
      prop-id="basenewcontentalert"
      prop-text="Foi encontrada uma nova versão do site!"
      prop-button-text="Atualizar Site"
      :prop-has-new-content="updateExistsServiceWorker"
      :prop-registration-service-worker-content="registrationServiceWorker"
      @close-base-new-content-service-worker="changeExistsServiceWorker($event)"
    />

    <metainfo />
    <router-view />
  </div>
</template>

<script>
import { defineComponent, ref, defineAsyncComponent, onMounted, onBeforeUnmount } from 'vue';
import { useI18n } from 'vue-i18n';
import { useMeta } from 'vue-meta';
import useMixins from '@mixins';

export default defineComponent({
  name: 'App',
  components: {
    VlalgNewContentServiceWorker: defineAsyncComponent(() =>
      import('@components/fragments/VlalgNewContentServiceWorker')
    ),
  },
  emits: [],
  props: {},
  setup () {
    /* MIXINS */
    const { localEnvs } = useMixins();

    /* VUE I18N Global */
    const { t, locale } = useI18n({
      inheritLocale: false,
      useScope: 'global',
    });

    /* VUE META */
    useMeta({
      title: 'Default Title',
      titleTemplate: (chunk) => `${chunk} | ${localEnvs.appName}`,
      htmlAttrs: {
        lang: 'pt-BR',
      },
    });

    /* VUE ROUTER */

    /* VUEX (Mesmo que this.$store) */

    /* FUNCTIONS */

    /* LIFECYCLE */
    onMounted(async () => {
      // Iniciando o EventListener do serviceWorker
      document.addEventListener('swUpdated', showBaseNewContentServiceWorker, { once: true });

      // Refrescando apagina depois que o serviceWorker atualizar o conteudo
      navigator.serviceWorker.addEventListener('controllerchange', refreshingAfterServiceWorker);
    });

    onBeforeUnmount(() => {
      // Finalizando o EventListener do serviceWorker
      document.removeEventListener('swUpdated', closeBaseNewContentServiceWorker);
    });

    /* VUEX ACTIONS */

    /* VUEX MUTATIONS */

    /* VUEX GETTERS */

    /* VUEX STATES */

    /* PROPS */

    /* DATA */
    const refreshingPageServiceWorker = ref(false);
    const updateExistsServiceWorker = ref(false);
    const registrationServiceWorker = ref(null);

    /* COMPUTED */

    /* METHODS */
    // Inico Funcoes do serviceWorker
    const refreshingAfterServiceWorker = () => {
      if (refreshingPageServiceWorker.value) return;

      refreshingPageServiceWorker.value = true;
      window.location.reload();
    };
    const showBaseNewContentServiceWorker = (e) => {
      registrationServiceWorker.value = e.detail;
      updateExistsServiceWorker.value = true;
    };
    const closeBaseNewContentServiceWorker = (e) => {
      registrationServiceWorker.value = e.detail;
      updateExistsServiceWorker.value = false;
    };
    const changeExistsServiceWorker = (e) => {
      registrationServiceWorker.value = null;
      updateExistsServiceWorker.value = e;
    };
    // Fim Funcoes do serviceWorker

    /* WATCH */

    return {
      t,
      locale,
      registrationServiceWorker,
      updateExistsServiceWorker,
      changeExistsServiceWorker,
    };
  },
});
</script>

<style lang="scss"></style>
