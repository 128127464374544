const SystemRecordsThemesPage = () => import('@views/system/records/themes/RecordsThemesPage')
const SystemRecordsThemesListPage = () => import('@views/system/records/themes/RecordsThemesListPage')
const SystemRecordsThemesCreatePage = () => import('@views/system/records/themes/RecordsThemesCreatePage')
const SystemRecordsThemesEditPage = () => import('@views/system/records/themes/RecordsThemesEditPage')

/* eslint-disable no-unused-vars */
export default {
  path: 'themes',
  name: 'SystemRecordsThemesPage',
  component: SystemRecordsThemesPage,
  children: [
    {
      path: '',
      name: 'SystemRecordsThemesListPage',
      component: SystemRecordsThemesListPage,
    },
    {
      path: 'create/:reference?',
      name: 'SystemRecordsThemesCreatePage',
      component: SystemRecordsThemesCreatePage,
    },
    {
      path: 'edit/:reference?',
      name: 'SystemRecordsThemesEditPage',
      component: SystemRecordsThemesEditPage,
      beforeEnter (to, from, next) {
        const { reference } = to.params

        if (!reference) {
          next({ name: 'SystemRecordsThemesListPage' })
        } else {
          next()
        }
      },
    },
  ],
}
