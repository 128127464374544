import { createRouter, createWebHistory } from 'vue-router'
import { routes } from '@router/routes.js'
import store from '@store'
import { getLocalToken, tokenIsValid, removeLocalToken, removeLocalRole } from '@helpers/functions'
import useMixins from '@mixins'

/* MIXINS */
const { localEnvs } = useMixins()

// Funcao para remover a variavel de localstorage goBackAfterLogin
async function removeGoBackAfterLogin () {
  // Removendo o goBackAfterLogin caso ele exista e a tela de destino nao é a de Login
  localStorage.removeItem('goBackAfterLogin')
}

function clearStore () {
  store.dispatch('ModuleErrors/clearErrorsStore')
  store.dispatch('ModuleLogin/clearLoginStore')
  store.dispatch('ModuleUser/clearUserStore')
  store.dispatch('clearStore')
}

const scrollBehavior = (to, from, savedPosition) => {
  // TODO remover este console depois
  console.log(from)

  if (savedPosition) {
    return savedPosition
  } else {
    const position = {}

    if (to.hash) {
      position.selector = to.hash

      if (to.hash === '#anchor2') {
        position.offset = { y: 100 }
      }

      if (document.querySelector(to.hash)) {
        return position
      }

      return false
    }

    return new Promise(() => {
      /*
        Para usar isso devemos passar um meta
        { path: '/', component: Home, meta: { scrollToTop: true }},
      */
      if (to.matched.some(m => m.meta.scrollToTop)) {
        position.x = 0
        position.y = 0
      }

      /*
        Para usar isso devemos passar um meta

        <div id="apap">
          <h1>Scroll Behavior</h1>
          <ul>
            <li><router-link to="/">/</router-link></li>
            <li><router-link to="/foo">/foo</router-link></li>
            <li><router-link to="/bar">/bar</router-link></li>
            <li><router-link to="/bar#anchor">/bar#anchor</router-link></li>
            <li><router-link to="/bar#anchor2">/bar#anchor2</router-link></li>
          </ul>
          <transition name="fade" mode="out-in" @after-leave="afterLeave">
            <router-view class="view"></router-view>
          </transition>
        </div>

        methods: {
          afterLeave () {
            this.$root.$emit('triggerScroll')
          }
        }
      */
      // (Router. ou this.$)app.$root.$once('triggerScroll', () => {
      // (Router. ou this.$)app.$nextTick(() => resolve(position))
      // })
    })
  }
}

const router = createRouter({
  history: createWebHistory(),
  base: localEnvs.baseUrl,
  linkActiveClass: 'router-link-active',
  linkExactActiveClass: 'router-link-exact-active',
  scrollBehavior,
  routes,
})

// Proteção das rotas (Auth)
router.beforeEach(async (to, from, next) => {
  // TODO remover este console depois
  console.log(from)

  const goHome = ['/active', '/forgot-password', '/reset-password']
  const goLogin = ['/login']

  // Obtendo usuadio/token salvo localmente
  const tokenLocalStorage = getLocalToken()
  const hasGoBackAfterLogin = localStorage.getItem('goBackAfterLogin')

  // Meta da rota
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

  // Verifica se token é valido e refresh se for
  if (tokenLocalStorage) {
    const isValidToken = await tokenIsValid(tokenLocalStorage)

    if (!isValidToken) {
      clearStore()

      if (requiresAuth) {
        next({ name: 'LoginPage' })
      } else {
        // Remove o token local
        removeLocalToken()

        // Remove a role local
        removeLocalRole()
        next({ name: 'HomePage' })
      }
    }
  }

  if (!tokenLocalStorage && requiresAuth) {
    // Salvando a rota de destino para depois voltar o usuario para ela
    // Apagada apenas depois que efetuar o login ou se existir e a rota nao for de login e nem bloqueada
    localStorage.setItem('goBackAfterLogin', JSON.stringify(to.path))

    next({ name: 'LoginPage' })
  }

  if (hasGoBackAfterLogin) {
    await removeGoBackAfterLogin()

    if (tokenLocalStorage && goLogin.includes(hasGoBackAfterLogin)) {
      next({ name: 'SystemDashboardPage' })
    } else if (tokenLocalStorage && goHome.includes(hasGoBackAfterLogin)) {
      next({ name: 'HomePage' })
    } else {
      next()
    }
  } else if (tokenLocalStorage && goLogin.includes(to.path)) {
    next({ name: 'SystemDashboardPage' })
  } else if (tokenLocalStorage && goHome.includes(to.path)) {
    next({ name: 'HomePage' })
  } else {
    next()
  }
})

export default router
