
import profile from './profile'
import config from './config'
import help from './help'
import messages from './messages'
import leaderboard from './leaderboard'
import records from './records'
import recipe from './recipe'
import news from './news'
import video from './video'
import season from './season'
import contact from './contact'
import cart from './cart'
import achievements from './achievements'
import reward from './reward'

const SystemBodyPage = () => import('@views/system/SystemBodyPage')
const SystemDashboardPage = () => import('@views/system/DashboardPage')

export default {
  path: '/system',
  component: SystemBodyPage,
  props: false,
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: '',
      name: 'SystemDashboardPage',
      component: SystemDashboardPage,
    },
    profile,
    config,
    help,
    messages,
    leaderboard,
    records,
    recipe,
    news,
    video,
    season,
    contact,
    cart,
    achievements,
    reward
  ],
}
