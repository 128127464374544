export default {
  username: {
    placeholder: 'email'
  },
  password: {
    placeholder: 'senha',
    name: 'inputpassword'
  },
  email: {
    placeholder: 'email',
    name: 'inputemail'
  }
}
