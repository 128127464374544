const NewsletterPage = () => import('@views/website/NewsletterPage')
const NewsletterUnsubscribePage = () => import('@views/website/NewsletterUnsubscribePage')

/* eslint-disable no-unused-vars */
export default [
  {
    path: '/newsletter',
    name: 'NewsletterPage',
    component: NewsletterPage,
  },
  {
    path: '/newsletter-unsubscribe',
    name: 'NewsletterUnsubscribePage',
    component: NewsletterUnsubscribePage,
  }
]
