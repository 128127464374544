import { createI18n } from 'vue-i18n'
import useMixins from '@mixins'
import languages from '@locales/index'

/* MIXINS */
const { localEnvs } = useMixins()

function loadLocaleMessages () {
  const locales = languages
  console.log(locales)
  const messages = {}

  Object.keys(locales).forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)/i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales[locale]
    }
  })

  return messages
}

function getLocale () {
  const LSLocale = localStorage.getItem('locale')
  const validLocales = ['en', 'pt-BR']
  const defaultLocale = process.env.VUE_APP_I18N_LOCALE || 'pt-BR'

  if (LSLocale) return LSLocale

  let locale = ''

  const userLocale = navigator.languages[0] || navigator.language

  if (userLocale && validLocales.includes(userLocale)) {
    locale = userLocale
  } else {
    locale = defaultLocale
  }

  return locale
}

export default createI18n({
  legacy: false,
  globalInjection: true,
  locale: getLocale(),
  fallbackLocale: localEnvs.i18nFallbackLocale || 'en',
  messages: loadLocaleMessages(),
})
