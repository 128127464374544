const SystemRecordsUserTypePage = () => import('@views/system/records/userType/RecordsUserTypePage')
const SystemRecordsUserTypeListPage = () => import('@views/system/records/userType/RecordsUserTypeListPage')
const SystemRecordsUserTypeCreatePage = () => import('@views/system/records/userType/RecordsUserTypeCreatePage')
const SystemRecordsUserTypeEditPage = () => import('@views/system/records/userType/RecordsUserTypeEditPage')

/* eslint-disable no-unused-vars */
export default {
  path: 'user-types',
  name: 'SystemRecordsUserTypePage',
  component: SystemRecordsUserTypePage,
  children: [
    {
      path: '',
      name: 'SystemRecordsUserTypeListPage',
      component: SystemRecordsUserTypeListPage,
    },
    {
      path: 'create/:reference?',
      name: 'SystemRecordsUserTypeCreatePage',
      component: SystemRecordsUserTypeCreatePage,
    },
    {
      path: 'edit/:reference?',
      name: 'SystemRecordsUserTypeEditPage',
      component: SystemRecordsUserTypeEditPage,
      beforeEnter (to, from, next) {
        const { reference } = to.params

        if (!reference) {
          next({ name: 'SystemRecordsUserTypeListPage' })
        } else {
          next()
        }
      },
    },
  ],
}
