import basics from './basics'
import login from './login'
import account from './account'

/* eslint-disable no-unused-vars */
export default [
  basics,
  login,
  ...account
]
