export default {
  CLEAR_STORE (state) {
    state.block_ui = false
    state.theme = 'dark'
    state.location = ''
  },
  CHANGE_BLOCK_UI (state, payload) {
    state.block_ui = payload
  },
  CHANGE_THEME (state, payload) {
    state.theme = payload
  },
  CHANGE_LOCATION (state, payload) {
    state.location = payload
  },
  CHANGE_LOGIN_ATTEMPTS (state, payload) {
    state.loginAttempts = payload
  },
  CHANGE_SUB_NEWSLETTER_ATTEMPTS (state, payload) {
    state.newsletterAttempts = payload
  },
}
