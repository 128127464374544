export default {
  CLEAR_USER_STORE (state) {
    state.id = ''
    state.profile = ''
    state.role = ''
  },
  CHANGE_USER_ID (state, payload) {
    state.id = payload
  },
  CHANGE_USER_PROFILE (state, payload) {
    state.profile = payload
  },
  CHANGE_USER_ROLE (state, payload) {
    state.role = payload
  },
}
