export default {
  html: {
    title: 'Receitas'
  },
  title: 'Receitas',
  description: 'Alguma das receitas elaboradas por nós',
  emptyList: {
    phraseOne: 'Ah, infelizmente não temos receitas no momento!',
    phraseTwo: 'Será um prazer rever você em breve, até mais.'
  },
  buttons: {
    getMoreItems: 'Mais Receitas'
  }
}
