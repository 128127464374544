const SystemRecordsTagsPage = () => import('@views/system/records/tags/RecordsTagsPage')
const SystemRecordsTagsListPage = () => import('@views/system/records/tags/RecordsTagsListPage')
const SystemRecordsTagsCreatePage = () => import('@views/system/records/tags/RecordsTagsCreatePage')
const SystemRecordsTagsEditPage = () => import('@views/system/records/tags/RecordsTagsEditPage')

/* eslint-disable no-unused-vars */
export default {
  path: 'tags',
  name: 'SystemRecordsTagsPage',
  component: SystemRecordsTagsPage,
  children: [
    {
      path: '',
      name: 'SystemRecordsTagsListPage',
      component: SystemRecordsTagsListPage,
    },
    {
      path: 'create/:reference?',
      name: 'SystemRecordsTagsCreatePage',
      component: SystemRecordsTagsCreatePage,
    },
    {
      path: 'edit/:reference?',
      name: 'SystemRecordsTagsEditPage',
      component: SystemRecordsTagsEditPage,
      beforeEnter (to, from, next) {
        const { reference } = to.params

        if (!reference) {
          next({ name: 'SystemRecordsTagsListPage' })
        } else {
          next()
        }
      },
    },
  ],
}
