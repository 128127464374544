import axios from 'axios'
import useMixins from '@mixins'

/* MIXINS */
const { localEnvs } = useMixins()

/*
  USER
*/
// Funções da rota de login
async function authUser (email, password) {
  const url = `${localEnvs.restApi.v1.base}/users/authenticate`
  const bodyData = {
    email,
    password,
  }

  const result = await axios
    .post(url, bodyData)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })

  return result
}

// Funções da rota de refresh-token
async function validRefreshToken (token) {
  const url = `${localEnvs.restApi.v1.base}/users/refresh-token`
  const bodyData = {}
  const axiosConfig = {
    headers: {
      'x-access-token': token,
    },
  }

  try {
    const result = await axios
      .post(url, bodyData, axiosConfig)
      .then(response => {
        return response
      })
      .catch(error => {
        return error.response
      })

    return result
  } catch (error) {
    return {
      developerMessage: 'Unprocessable Entity',
      errorCode: '01004224',
      moreInfo: 'http://localhost:5000/developer/api/v1/help/01004224',
      status: 422,
      userMessage: 'Token is not valid more',
    }
  }
}

// Funções da rota de refresh-token
async function getUser (user, filters, token) {
  const url = `${localEnvs.restApi.v1.base}/users/${user}?${filters}`
  const axiosConfig = {
    headers: {
      'x-access-token': token,
      'Content-Type': 'application/json',
    },
  }

  const result = await axios
    .get(url, axiosConfig)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })

  return result
}

// Funções da rota de refresh-token
async function updateUser (user, values, token) {
  const url = `${localEnvs.restApi.v1.base}/users/${user}`
  const axiosConfig = {
    headers: {
      'x-access-token': token,
    },
  }

  const result = await axios
    .put(url, values, axiosConfig)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })

  return result
}

// Funções da Rota Active (ativar conta)
async function activeUser (token, email) {
  if (token && email) return true
  return false
}

// Funções da Rota Forgot Password
async function forgotPassword (email) {
  const url = `${localEnvs.restApi.v1.base}/users/forgot-password`
  const bodyData = {
    email,
  }
  const axiosConfig = {}

  const result = await axios
    .post(url, bodyData, axiosConfig)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })

  return result
}

async function userHasForgotPassword (token, email) {
  if (token && email) return true
  return false
}

// Funções da Rota Reset Password
async function resetPassword (email, code, password) {
  if (email && code && password) return true
  return false
}

// Funções da Rota Users (count)
async function countUsers (data = {}, page = '', limit = '', fields = '', sort = '', status = '', token = '') {
  let newPage = 1
  let newLimit = 20
  let newFields = 'id,created_at,updated_at'
  let newSort = '-created_at'
  let newStatus = 'active'

  if (page) newPage = page
  if (limit) newLimit = limit
  if (fields) newFields = fields
  if (sort) newSort = sort
  if (status) newStatus = status

  const axiosConfig = {
    method: 'get',
    url: '',
    headers: {
      'x-access-token': token,
      'Content-Type': 'application/json',
    },
    params: {
      personal: JSON.stringify(data),
    },
  }

  axiosConfig.url = `${localEnvs.restApi.v1.base}/users?page=${newPage}&limit=${newLimit}&fields=${newFields}&sort=${newSort}&status=${newStatus}`

  const result = await axios(axiosConfig)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })

  return result
}

export default {
  authUser,
  validRefreshToken,
  getUser,
  updateUser,
  activeUser,
  forgotPassword,
  userHasForgotPassword,
  resetPassword,
  countUsers,
}
