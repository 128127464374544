const SystemRecordsMediaFanPage = () => import('@views/system/records/mediaFan/RecordsMediaFanPage')
const SystemRecordsMediaFanListPage = () => import('@views/system/records/mediaFan/RecordsMediaFanListPage')
const SystemRecordsMediaFanCreatePage = () => import('@views/system/records/mediaFan/RecordsMediaFanCreatePage')
const SystemRecordsMediaFanEditPage = () => import('@views/system/records/mediaFan/RecordsMediaFanEditPage')

/* eslint-disable no-unused-vars */
export default {
  path: 'medias-fan',
  name: 'SystemRecordsMediaFanPage',
  component: SystemRecordsMediaFanPage,
  children: [
    {
      path: '',
      name: 'SystemRecordsMediaFanListPage',
      component: SystemRecordsMediaFanListPage,
    },
    {
      path: 'create/:reference?',
      name: 'SystemRecordsMediaFanCreatePage',
      component: SystemRecordsMediaFanCreatePage,
    },
    {
      path: 'edit/:reference?',
      name: 'SystemRecordsMediaFanEditPage',
      component: SystemRecordsMediaFanEditPage,
      beforeEnter (to, from, next) {
        const { reference } = to.params

        if (!reference) {
          next({ name: 'SystemRecordsMediaFanListPage' })
        } else {
          next()
        }
      },
    },
  ],
}
