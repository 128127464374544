import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import { createMetaManager } from 'vue-meta'

import './registerServiceWorker'

// Plugins
import i18n from '@/i18n'
import '@/plugins/pluginExemplo'

import { library } from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(far)
library.add(fas)
library.add(fab)

const vueMeta = createMetaManager()

const app = createApp(App).use(store).use(router).use(i18n).use(vueMeta).component('font-awesome-icon', FontAwesomeIcon)

router.isReady().then(() => {
  app.mount('#app')
})
