import spacetime from 'spacetime'

const config = {
  timezone: 'America/Sao_Paulo',
  formatterDate: '{date-pad}/{iso-month}/{year}',
  formatterTime: '{hour-24-pad}:{minute-pad}:{second-pad} {ampm}',
}
const AllowedTypes = ['day', 'month', 'year', 'week', 'quarter']

// Funcao formata data e hora
export const formatDatetime = (datetime = '', timezone = '', dateFormatter = '', hourFormatter = '') => {
  let currentDatetime = spacetime(datetime)

  let defaultTimezone = config.timezone
  let defaultDateFormatter = config.formatterDate
  let defaultHourFormatter = config.formatterTime

  if (!currentDatetime.isValid()) return 'Invalid datetime'

  if (timezone) defaultTimezone = timezone

  if (dateFormatter) defaultDateFormatter = dateFormatter

  if (hourFormatter) defaultHourFormatter = hourFormatter

  currentDatetime = currentDatetime.goto(defaultTimezone)

  const currentDate = currentDatetime.format(defaultDateFormatter)
  const currentTime = currentDatetime.format(defaultHourFormatter)
  return {
    date: currentDate,
    time: currentTime,
  }
}

// Funcao que retorna a data de hj
export const datetimeNow = (timezone = '', dateFormatter = '', hourFormatter = '') => {
  let defaultTimezone = config.timezone
  let defaultDateFormatter = config.formatterDate
  let defaultHourFormatter = config.formatterTime

  if (timezone) defaultTimezone = timezone

  if (dateFormatter) defaultDateFormatter = dateFormatter

  if (hourFormatter) defaultHourFormatter = hourFormatter

  const currentDatetime = spacetime.now(defaultTimezone)

  const currentDate = currentDatetime.format(defaultDateFormatter)
  const currentTime = currentDatetime.format(defaultHourFormatter)

  return {
    date: currentDate,
    time: currentTime,
  }
}

// Funcao que retorna a data de hoje primeiro horario
export const datetimeToday = (timezone = '', dateFormatter = '', hourFormatter = '') => {
  let defaultTimezone = config.timezone
  let defaultDateFormatter = config.formatterDate
  let defaultHourFormatter = config.formatterTime

  if (timezone) defaultTimezone = timezone

  if (dateFormatter) defaultDateFormatter = dateFormatter

  if (hourFormatter) defaultHourFormatter = hourFormatter

  const currentDatetime = spacetime.today(defaultTimezone)

  const currentDate = currentDatetime.format(defaultDateFormatter)
  const currentTime = currentDatetime.format(defaultHourFormatter)

  return {
    date: currentDate,
    time: currentTime,
  }
}

// Function that returns year from datetime
export const datetimeYear = (datetime) => {
  return spacetime(datetime).year()
}

// Funcao que retorna a data de hoje primeiro horario
// Se receber YYYY/MM/DD converte para YYYY-MM-DD
export const datetimeStartEnd = (datetime = '2014-01-01') => {
  const replaced = datetime.includes('/') ? datetime.replaceAll('/', '-') : datetime

  return {
    startHour: `${replaced}T00:00:00.000Z`,
    endHour: `${replaced}T23:59:59.999Z`,
  }
}

// Funcao que retorna a data de ontem no primeiro horario
export const datetimeTomorrow = (timezone = '', dateFormatter = '', hourFormatter = '') => {
  let defaultTimezone = config.timezone
  let defaultDateFormatter = config.formatterDate
  let defaultHourFormatter = config.formatterTime

  if (timezone) defaultTimezone = timezone

  if (dateFormatter) defaultDateFormatter = dateFormatter

  if (hourFormatter) defaultHourFormatter = hourFormatter

  const currentDatetime = spacetime.tomorrow(defaultTimezone)

  const currentDate = currentDatetime.format(defaultDateFormatter)
  const currentTime = currentDatetime.format(defaultHourFormatter)

  return {
    date: currentDate,
    time: currentTime,
  }
}

// Funcao que adiciona ou Subtrai (day, month, year, week, quarter) na data
export const datetimeAddOrSubtract = (
  datetime = '',
  timezone = '',
  segment = '',
  type = '',
  value = '',
  dateFormatter = '',
  hourFormatter = ''
) => {
  let currentDatetime = spacetime(datetime)
  let defaultTimezone = config.timezone
  let defaultDateFormatter = config.formatterDate
  let defaultHourFormatter = config.formatterTime
  let defaultValue = 1
  let defaultType = 'day'
  let defaultSegment = 'add'
  const AllowedSegments = ['add', 'subtract']

  if (!currentDatetime.isValid()) return 'Invalid datetime'

  if (timezone) defaultTimezone = timezone

  if (dateFormatter) defaultDateFormatter = dateFormatter

  if (hourFormatter) defaultHourFormatter = hourFormatter

  if (value) defaultValue = value

  if (type && AllowedTypes.includes(type)) defaultType = type

  if (segment && AllowedSegments.includes(segment)) defaultSegment = segment

  if (defaultSegment === 'add') {
    currentDatetime = currentDatetime.add(defaultValue, defaultType)
  } else {
    currentDatetime = currentDatetime.subtract(defaultValue, defaultType)
  }

  currentDatetime = currentDatetime.goto(defaultTimezone)

  const currentDate = currentDatetime.format(defaultDateFormatter)
  const currentTime = currentDatetime.format(defaultHourFormatter)

  return {
    date: currentDate,
    time: currentTime,
  }
}

// Funcao que adiciona ou Subtrai (day, month, year, week, quarter) na data
export const datetimeAddAndSubtract = (
  datetime = '',
  timezone = '',
  subtractType = '',
  subtractValue = '',
  addType = '',
  addValue = '',
  dateFormatter = '',
  hourFormatter = ''
) => {
  let currentDatetime = spacetime(datetime)
  let defaultTimezone = config.timezone
  let defaultDateFormatter = config.formatterDate
  let defaultHourFormatter = config.formatterTime
  let defaultSubtractValue = 1
  let defaultSubtractType = 'months'
  let defaultAddValue = 1
  let defaultAddType = 'day'

  if (!currentDatetime.isValid()) return 'Invalid datetime'

  if (timezone) defaultTimezone = timezone

  if (dateFormatter) defaultDateFormatter = dateFormatter

  if (hourFormatter) defaultHourFormatter = hourFormatter

  if (subtractValue) defaultSubtractValue = subtractValue

  if (addValue) defaultAddValue = addValue

  if (subtractType && AllowedTypes.includes(subtractType)) defaultSubtractType = subtractType

  if (addType && AllowedTypes.includes(addType)) defaultAddType = addType

  currentDatetime = currentDatetime
    .subtract(defaultSubtractValue, defaultSubtractType)
    .add(defaultAddValue, defaultAddType)

  currentDatetime = currentDatetime.goto(defaultTimezone)

  const currentDate = currentDatetime.format(defaultDateFormatter)
  const currentTime = currentDatetime.format(defaultHourFormatter)

  return {
    date: currentDate,
    time: currentTime,
  }
}

// Funcao que gera uum range de horas
export const generateHours = (startHour = 9, endHour = 18) => {
  const listHour = []

  for (let hour = startHour; hour < endHour; hour++) {
    const textHour = `0${hour}`.substr(-2)
    listHour.push(generateMinutesForHour(textHour))
  }

  return listHour.reduce((previousValue, currentValue) => [...previousValue, ...currentValue], [])
}

function generateMinutesForHour (hour, { intervalBetweenMinutes = 30, maxMinute = 30 } = {}) {
  const listHourWithMinutes = []

  for (let minute = 0; minute <= maxMinute; minute += intervalBetweenMinutes) {
    const textMinute = `0${minute}`.substr(-2)
    listHourWithMinutes.push(`${hour}:${textMinute}`)
  }

  return listHourWithMinutes
}

// Funcao que retorna o tempo da data
export const datetimeHowMuchTime = (datetime = '', timezone = '') => {
  let today = spacetime()

  let currentDatetime = spacetime(datetime)

  let defaultTimezone = config.timezone

  if (!currentDatetime.isValid()) return 'Invalid datetime'

  if (currentDatetime.isAfter(today)) return 'Invalid datetime'

  if (timezone) defaultTimezone = timezone

  currentDatetime = currentDatetime.goto(defaultTimezone)
  today = today.goto(defaultTimezone)

  const diffDatetime = today.since(currentDatetime)

  return diffDatetime
}

// 
export const diffBetweenTwoTimes = (datetime) => {
  const now = spacetime.now()
  const before = spacetime(datetime)
  const since = now.since(before).rounded

  if (since !== 'now') {
    return formatToI18n(since)
  }
  return 'now'
}

export const formatToI18n = (datetime) => {
  const array = datetime.split(' ')

  const duration = array[0]
  const diff = array[1]
  const direction = array[2]

  const formattedDiff = diff.endsWith('s') ? diff : `${diff}s`

  return { duration, formattedDiff, direction }
}

// Function that returns years list
export const yearList = (afterNow = 10, beforeNow = 0) => {
  const currentYear = datetimeYear()
  const yearStart = currentYear - afterNow
  const defaultList = []
  const nextYears = afterNow

  for (let i = 0; i <= nextYears; i++) {
    defaultList.push(yearStart + i)
  }

  if (beforeNow > 0) {
    for (let i = 0; i <= beforeNow; i++) {
      defaultList.push(currentYear + i)
    }
  }

  return defaultList
}
