import active from './active'
import forgotPassword from './forgotPassword'
import signUp from './signUp'
import resetPassword from './resetPassword'

export default [
  active,
  forgotPassword,
  signUp,
  resetPassword
]
