export default {
  clearUserStore (context, payload) {
    context.commit('CLEAR_USER_STORE', payload)
  },
  changeUserId (context, payload) {
    context.commit('CHANGE_USER_ID', payload)
  },
  changeUserProfile (context, payload) {
    context.commit('CHANGE_USER_PROFILE', payload)
  },
  changeUserRole (context, payload) {
    context.commit('CHANGE_USER_ROLE', payload)
  },
}
