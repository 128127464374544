import axios from 'axios'
import useMixins from '@mixins'

/* MIXINS */
const { localEnvs } = useMixins()

/*
  RECIPES
*/
// Função da Rota Receitas (All)
async function getRecipesList (page = '', limit = '', fields = '', sort = '', status = '') {
  let newPage = 1
  let newLimit = 20
  let newFields =
    'id,name,description,url_name,themes,tags,publication_status,publication_date,hashtags,images,views,likes,rating,ingredients,equipments,comments,status,user_creator,user_updater,created_at,updated_at'
  let newSort = '-created_at'
  let newStatus = 'active'

  if (page) newPage = page
  if (limit) newLimit = limit
  if (fields) newFields = fields
  if (sort) newSort = sort
  if (status) newStatus = status

  const url = `${localEnvs.restApi.v1.base}/recipes?page=${newPage}&limit=${newLimit}&fields=${newFields}&sort=${newSort}&status=${newStatus}`
  const axiosConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  const result = await axios
    .get(url, axiosConfig)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })

  return result
}

// Função da Rota Receitas (por url name)
async function getRecipeByUrlName (routeName = '', fields = '') {
  let newFields =
    'id,name,description,url_name,themes,tags,publication_status,publication_date,hashtags,images,views,likes,rating,ingredients,equipments,comments,status,user_creator,user_updater,created_at,updated_at'

  if (fields) newFields = fields

  const url = `${localEnvs.restApi.v1.base}/recipes/name/${routeName}?fields=${newFields}`
  const axiosConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  const result = await axios
    .get(url, axiosConfig)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })

  return result
}

// Funções da Rota Recipes (count)
async function countRecipes (data = {}, page = '', limit = '', fields = '', sort = '', status = '', token = '') {
  let newPage = 1
  let newLimit = 20
  let newFields = 'id,created_at,updated_at'
  let newSort = '-created_at'
  let newStatus = 'active'

  if (page) newPage = page
  if (limit) newLimit = limit
  if (fields) newFields = fields
  if (sort) newSort = sort
  if (status) newStatus = status

  const axiosConfig = {
    method: 'get',
    url: '',
    headers: {
      'x-access-token': token,
      'Content-Type': 'application/json',
    },
    params: {
      personal: JSON.stringify(data),
    },
  }

  axiosConfig.url = `${localEnvs.restApi.v1.base}/recipes?page=${newPage}&limit=${newLimit}&fields=${newFields}&sort=${newSort}&status=${newStatus}`

  const result = await axios(axiosConfig)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })

  return result
}

export default {
  getRecipesList,
  getRecipeByUrlName,
  countRecipes,
}
