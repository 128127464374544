const SystemRecordsRecipePage = () => import('@views/system/records/recipe/RecordsRecipePage')
const SystemRecordsRecipeListPage = () => import('@views/system/records/recipe/RecordsRecipeListPage')
const SystemRecordsRecipeCreatePage = () => import('@views/system/records/recipe/RecordsRecipeCreatePage')
const SystemRecordsRecipeEditPage = () => import('@views/system/records/recipe/RecordsRecipeEditPage')

/* eslint-disable no-unused-vars */
export default {
  path: 'recipes',
  name: 'SystemRecordsRecipePage',
  component: SystemRecordsRecipePage,
  children: [
    {
      path: '',
      name: 'SystemRecordsRecipeListPage',
      component: SystemRecordsRecipeListPage,
    },
    {
      path: 'create/:reference?',
      name: 'SystemRecordsRecipeCreatePage',
      component: SystemRecordsRecipeCreatePage,
    },
    {
      path: 'edit/:reference?',
      name: 'SystemRecordsRecipeEditPage',
      component: SystemRecordsRecipeEditPage,
      beforeEnter (to, from, next) {
        const { reference } = to.params

        if (!reference) {
          next({ name: 'SystemRecordsRecipeListPage' })
        } else {
          next()
        }
      },
    },
  ],
}
