const SystemRecordsImagesPage = () => import('@views/system/records/images/RecordsImagesPage')
const SystemRecordsImagesListPage = () => import('@views/system/records/images/RecordsImagesListPage')
const SystemRecordsImagesCreatePage = () => import('@views/system/records/images/RecordsImagesCreatePage')
const SystemRecordsImagesEditPage = () => import('@views/system/records/images/RecordsImagesEditPage')

/* eslint-disable no-unused-vars */
export default {
  path: 'images',
  name: 'SystemRecordsImagesPage',
  component: SystemRecordsImagesPage,
  children: [
    {
      path: '',
      name: 'SystemRecordsImagesListPage',
      component: SystemRecordsImagesListPage,
    },
    {
      path: 'create/:reference?',
      name: 'SystemRecordsImagesCreatePage',
      component: SystemRecordsImagesCreatePage,
    },
    {
      path: 'edit/:reference?',
      name: 'SystemRecordsImagesEditPage',
      component: SystemRecordsImagesEditPage,
      beforeEnter (to, from, next) {
        const { reference } = to.params

        if (!reference) {
          next({ name: 'SystemRecordsImagesListPage' })
        } else {
          next()
        }
      },
    },
  ],
}
