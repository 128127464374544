import axios from 'axios'
import b64toBlob from 'b64-to-blob'
import useMixins from '@mixins'

/* MIXINS */
const { localEnvs } = useMixins()

/*
  IMAGES
*/
// Função da Rota Receitas (create)
/*
    <input @change="previewFiles" type="file" name="" id="" >

    import helpersQueries from '@helpersQueries'
    const { createImages } = helpersQueries

    import { getLocalToken } from '@helpers/functions'

    const previewFiles = async (file) => {
      const image = file.target.files[0]
      console.log('file -> ', image)
      const token = getLocalToken()

      const resultImage = await createImages(image, token)

      console.log('resultImage -> ', resultImage)
    }
*/

async function createImages (itens, token) {
  const url = `${localEnvs.restApi.v1.base}/images`
  const axiosConfig = {
    headers: {
      'x-access-token': token,
      'Content-Type': 'multipart/form-data',
    },
  }

  const fd = new FormData()
  fd.append('fileField', itens)
  fd.append('status', '5ddef533d12eb922b0bb561a')
  fd.append('creator', '5ddef533d12eb922b0bb561e')

  const result = await axios
    .post(url, fd, axiosConfig)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })

  return result
}

// Funções da Rota Imagens (resize)
async function getResizedImage (imageId = '', format = 'png', width = '50', height = '500') {
  const newFormat = format
  const newWidth = width
  const newHeight = height

  const axiosConfig = {
    method: 'get',
    url: ''
  }

  const newQuery = []
  if (newFormat) newQuery.push(newFormat)
  if (newWidth) newQuery.push(newWidth)
  if (newHeight) newQuery.push(newHeight)

  if (!newQuery) return {}

  axiosConfig.url = `${localEnvs.restApi.v1.base}/image-resize/${imageId}?format=${newFormat}&width=${newWidth}&height=${newHeight}`

  const result = await axios(axiosConfig)
    .then(response => {
      const data = response.data
      const blob = b64toBlob(data.b64Data, data.contentType);
      const blobUrl = URL.createObjectURL(blob)

      // To use this value in a src attr
      return blobUrl
    })
    .catch(error => {
      return error.response
    })

  return result
}

export default {
  createImages,
  getResizedImage
}
