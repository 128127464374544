const SystemRecordsMeasuresUnitPage = () => import('@views/system/records/measuresUnit/RecordsMeasuresUnitPage')
const SystemRecordsMeasuresUnitListPage = () => import('@views/system/records/measuresUnit/RecordsMeasuresUnitListPage')
const SystemRecordsMeasuresUnitCreatePage = () => import('@views/system/records/measuresUnit/RecordsMeasuresUnitCreatePage')
const SystemRecordsMeasuresUnitEditPage = () => import('@views/system/records/measuresUnit/RecordsMeasuresUnitEditPage')

/* eslint-disable no-unused-vars */
export default {
  path: 'measures-unit',
  name: 'SystemRecordsMeasuresUnitPage',
  component: SystemRecordsMeasuresUnitPage,
  children: [
    {
      path: '',
      name: 'SystemRecordsMeasuresUnitListPage',
      component: SystemRecordsMeasuresUnitListPage,
    },
    {
      path: 'create/:reference?',
      name: 'SystemRecordsMeasuresUnitCreatePage',
      component: SystemRecordsMeasuresUnitCreatePage,
    },
    {
      path: 'edit/:reference?',
      name: 'SystemRecordsMeasuresUnitEditPage',
      component: SystemRecordsMeasuresUnitEditPage,
      beforeEnter (to, from, next) {
        const { reference } = to.params

        if (!reference) {
          next({ name: 'SystemRecordsMeasuresUnitListPage' })
        } else {
          next()
        }
      },
    },
  ],
}
