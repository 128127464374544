export default {
  'save': 'Salvar',
  'close': 'Fechar',
  'cancel': 'Cancelar',
  'create': 'Criar',
  'delete': 'Deletar',
  'forgotPassword': 'Esqueceu a senha?',
  'newUser': 'Sou novato!',
  'signIn': 'Entrar',
  'signUp': 'Cadastrar',
  'logout': 'Sair',
  'send': 'Enviar',
  'comeBack': 'Voltar',
  'subscribe': 'Assinar'
}
