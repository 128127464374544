export default {
  clearStore (context, payload) {
    context.commit('CLEAR_STORE', payload)
  },
  changeBlockUi (context, payload) {
    context.commit('CHANGE_BLOCK_UI', payload)
  },
  changeTheme (context, payload) {
    context.commit('CHANGE_THEME', payload)
  },
  changeLocation (context, payload) {
    context.commit('CHANGE_LOCATION', payload)
  },
  changeLoginAttempts (context, payload) {
    context.commit('CHANGE_LOGIN_ATTEMPTS', payload)
  },
  changeSubNewsletterAttempts (context, payload) {
    context.commit('CHANGE_SUB_NEWSLETTER_ATTEMPTS', payload)
  },
}
