export default {
  general: {},
  pages: {
    system: {},
    website: {
      forgotPasswordPage: {
        success: 'Foi encaminhado um e-mail com instruções para criar uma nova senha.'
      },
      loginPage: {
        loginFailed: 'Erro ao efetuar o login, tente novamente mais tarde.'
      }
    }
  }
}
