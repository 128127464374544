const SystemRecordsUserPermissionPage = () => import('@views/system/records/userPermission/RecordsUserPermissionPage')
const SystemRecordsUserPermissionListPage = () => import('@views/system/records/userPermission/RecordsUserPermissionListPage')
const SystemRecordsUserPermissionCreatePage = () => import('@views/system/records/userPermission/RecordsUserPermissionCreatePage')
const SystemRecordsUserPermissionEditPage = () => import('@views/system/records/userPermission/RecordsUserPermissionEditPage')

/* eslint-disable no-unused-vars */
export default {
  path: 'user-permissions',
  name: 'SystemRecordsUserPermissionPage',
  component: SystemRecordsUserPermissionPage,
  children: [
    {
      path: '',
      name: 'SystemRecordsUserPermissionListPage',
      component: SystemRecordsUserPermissionListPage,
    },
    {
      path: 'create/:reference?',
      name: 'SystemRecordsUserPermissionCreatePage',
      component: SystemRecordsUserPermissionCreatePage,
    },
    {
      path: 'edit/:reference?',
      name: 'SystemRecordsUserPermissionEditPage',
      component: SystemRecordsUserPermissionEditPage,
      beforeEnter (to, from, next) {
        const { reference } = to.params

        if (!reference) {
          next({ name: 'SystemRecordsUserPermissionListPage' })
        } else {
          next()
        }
      },
    },
  ],
}
