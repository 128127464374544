import websiteModule from './modules/website'
import docsModule from './modules/docs'
import systemModule from './modules/system'

// Construction pages
// const UnderConstructionPage = () => import('@views/UnderConstructionPage')

/* eslint-disable no-unused-vars */
export const routes = [
  ...websiteModule,
  docsModule,
  systemModule,
  { path: '/:pathMatch(.*)*', redirect: '/404' },
  { path: '/:pathMatch(.*)', redirect: '/404' },
]
