const SystemRecordsVideosPage = () => import('@views/system/records/videos/RecordsVideosPage')
const SystemRecordsVideosListPage = () => import('@views/system/records/videos/RecordsVideosListPage')
const SystemRecordsVideosCreatePage = () => import('@views/system/records/videos/RecordsVideosCreatePage')
const SystemRecordsVideosEditPage = () => import('@views/system/records/videos/RecordsVideosEditPage')

/* eslint-disable no-unused-vars */
export default {
  path: 'videos',
  name: 'SystemRecordsVideosPage',
  component: SystemRecordsVideosPage,
  children: [
    {
      path: '',
      name: 'SystemRecordsVideosListPage',
      component: SystemRecordsVideosListPage,
    },
    {
      path: 'create/:reference?',
      name: 'SystemRecordsVideosCreatePage',
      component: SystemRecordsVideosCreatePage,
    },
    {
      path: 'edit/:reference?',
      name: 'SystemRecordsVideosEditPage',
      component: SystemRecordsVideosEditPage,
      beforeEnter (to, from, next) {
        const { reference } = to.params

        if (!reference) {
          next({ name: 'SystemRecordsVideosListPage' })
        } else {
          next()
        }
      },
    },
  ],
}
