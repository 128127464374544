import axios from 'axios'
import useMixins from '@mixins'

/* MIXINS */
const { localEnvs } = useMixins()

/*
  NEWSLETTER
*/
// Funções do banner de newsletter
async function accpetNewsletter (email) {
  const axiosConfig = {
    method: 'POST',
    url: '',
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      email,
      name: 'Anonimo',
      status: '5ddef533d12eb922b0bb561a',
    },
  }
  axiosConfig.url = localEnvs.restApi.v1.newsletter
  axiosConfig.data.email = email

  const result = await axios(axiosConfig)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })

  return result
}

// Funções da Rota Newsletter
async function accpetToNewsletter (name, email, status) {
  const axiosConfig = {
    method: 'POST',
    url: '',
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      name,
      email,
      status,
    },
  }
  axiosConfig.url = localEnvs.restApi.v1.newsletter
  axiosConfig.data.name = name
  axiosConfig.data.email = email
  axiosConfig.data.status = status

  const result = await axios(axiosConfig)
    .then(response => {
      const obj = {}
      obj.status = 'success'
      obj.data = response

      return obj
    })
    .catch(() => {
      const obj = {}
      obj.status = 'error'
      obj.data = []

      return obj
    })

  return result
}

// Funções da Rota Newsletter
async function teste (page = '', limit = '', fields = '', sort = '', status = '', token = '') {
  let newPage = 1
  let newLimit = 20
  let newFields = 'id,name,description,url_name,url,thumbnail,status,user_creator,user_updater,created_at,updated_at'
  let newSort = '-created_at'
  let newStatus = 'active'

  if (page) newPage = page
  if (limit) newLimit = limit
  if (fields) newFields = fields
  if (sort) newSort = sort
  if (status) newStatus = status

  const url = `${localEnvs.restApi.v1.base}/newsletter?page=${newPage}&limit=${newLimit}&fields=${newFields}&sort=${newSort}&status=${newStatus}`
  const axiosConfig = {
    headers: {
      'x-access-token': token,
      'Content-Type': 'application/json',
    },
  }

  const result = await axios
    .get(url, axiosConfig)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })

  return result
}

// Funções da Rota Newsletter (count)
async function countNewsletter (data = {}, page = '', limit = '', fields = '', sort = '', status = '', token = '') {
  let newPage = 1
  let newLimit = 20
  let newFields = 'id,name,description,url_name,url,thumbnail,status,user_creator,user_updater,created_at,updated_at'
  let newSort = '-created_at'
  let newStatus = 'active'

  if (page) newPage = page
  if (limit) newLimit = limit
  if (fields) newFields = fields
  if (sort) newSort = sort
  if (status) newStatus = status

  const axiosConfig = {
    method: 'get',
    url: '',
    headers: {
      'x-access-token': token,
      'Content-Type': 'application/json',
    },
    params: {
      personal: JSON.stringify(data),
    },
  }

  axiosConfig.url = `${localEnvs.restApi.v1.base}/newsletter?page=${newPage}&limit=${newLimit}&fields=${newFields}&sort=${newSort}&status=${newStatus}`

  const result = await axios(axiosConfig)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })

  return result
}

// Funções da Rota Newsletter usubscriber
async function isEmailNewsletter (email) {
  if (email) return true
  return false
}

async function unsubscribeNewsletter (email) {
  if (email) return true
  return false
}

export default {
  accpetNewsletter,
  accpetToNewsletter,
  teste,
  countNewsletter,
  isEmailNewsletter,
  unsubscribeNewsletter,
}
