export default {
  blockUi (state) {
    return state.block_ui
  },
  theme (state) {
    return state.theme
  },
  location (state) {
    return state.location
  },
  loginAttempts (state) {
    return state.loginAttempts
  },
  newsletterAttempts (state) {
    return state.newsletterAttempts
  },
}
