import useMixins from '@mixins'

const LoginPage = () => import('@views/website/LoginPage')

/* eslint-disable no-unused-vars */
export default {
  path: '/login',
  name: 'LoginPage',
  component: LoginPage,
  async beforeEnter (to, from, next) {
    const { hiddenLogin } = useMixins()

    if (!hiddenLogin.value) {
      next()
    } else {
      next({ name: 'HomePage' })
    }
  },
}
