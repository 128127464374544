import useMixins from '@mixins'

import videos from './videos'
import images from './images'
import themes from './themes'
import status from './status'
import faq from './faq'
import terms from './terms'
import tags from './tags'
import newsletter from './newsletter'
import securityPolicies from './securityPolicies'
import privacyPolicies from './privacyPolicies'
import news from './news'
import mediaFan from './mediaFan'
import recipes from './recipes'
import user from './user'
import userPermission from './userPermission'
import userType from './userType'
import measuresSize from './measuresSize'
import measuresUnit from './measuresUnit'

const SystemRecordsPage = () => import('@views/system/RecordsPage')
const SystemRecordsHomePage = () => import('@views/system/records/RecordsHomePage')

/* eslint-disable no-unused-vars */
export default {
  path: 'records',
  name: 'SystemRecordsPage',
  component: SystemRecordsPage,
  meta: {
    admin: true,
    master: true,
    client: false,
  },
  async beforeEnter (to, from, next) {
    const { userHasRole } = useMixins()
    const { admin, master } = to.meta
    const validRole = !!(admin || master)

    if (validRole && (await userHasRole(['admin', 'master']))) {
      next()
    } else {
      next({ name: 'SystemDashboardPage' })
    }
  },
  children: [
    {
      path: '',
      name: 'SystemRecordsHomePage',
      component: SystemRecordsHomePage,
    },
    videos,
    images,
    themes,
    status,
    faq,
    terms,
    tags,
    newsletter,
    securityPolicies,
    privacyPolicies,
    news,
    mediaFan,
    recipes,
    user,
    userPermission,
    userType,
    measuresSize,
    measuresUnit,
  ],
}
