const ActivePage = () => import('@views/website/ActivePage')

/* eslint-disable no-unused-vars */
export default {
  path: '/active',
  name: 'ActivePage',
  component: ActivePage,
  async beforeEnter (to, from, next) {
    try {
      const { token, email } = to.query

      if (token && email) {
        next()
      } else {
        next({ name: 'HomePage' })
      }
    } catch (e) {
      next({ name: 'HomePage' })
    }
  },
}
